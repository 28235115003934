<template>
  <div class="row parent">
    <div class="flex md6 lg6">
      <va-inner-loading :loading="isLoading">
        <va-card :bordered="false" style="margin-top: 100px;">
          <div class="row flex" style="margin: 20px;">
              <div class="display-5" style="align-self: center">
                <img width="100" src="https://da-public-assets.s3.amazonaws.com/serverlesspresso/images/307-avatar-icon-calm-plus-outline.gif">
                <br>
                Register an account</div>
              <div class="row align--center">
                <va-card-content >Enter your phone number. We will send you a text message with a one-time password.</va-card-content>
            </div>
          </div>
          <div class="row flex">
            <div v-show="!isCodeRequested">

              <div class="row flex mb-4" style="margin: 20px;">

                <div class="row align-content--start">
                  <div class="flex xs3">
                    <div class="title">Country code</div>
                    <div class="va-input-wrapper va-input va-input--outline mb-1" style="float: left;padding-right: 5px; font-size: 36px; color:#333">+</div>
                    <va-input
                      class="mb-1"
                      v-model="countryCode"
                      placeholder="Country code"
                      outline
                      ref="countryCode"
                    />
                    <div class="title">{{ countryCodeRegion }}</div>
                  </div>
                  <div class="flex xs9">
                    <div class="title">Phone number</div>
                    <va-input
                      class="mb-1"
                      v-model="phone"
                      placeholder="Phone number"
                      outline
                      :disabled="!isCountryCodeValid"
                    />
                    <div class="title">{{ fullPhoneNumber }}</div>
                  </div>
                </div>
              </div>

            </div>
            <div class="row flex" style="margin: 20px;">
              <va-button
                :disabled="isCodeRequested"
                :rounded="false"
                @click="resetForm"
                class="ml-2">
                  Reset
              </va-button>
              <va-button
                :disabled="!isValid || isCodeRequested"
                :rounded="false"
                @click="getCode"
                class="mr-2">
                  Get code
              </va-button>
            </div>
          </div>
          <div v-show="showCodeSubmit" class="row flex" style="margin: 20px;">
            <va-input

              style="font-size: 24px;"
              :mask="{blocks: [6]}"
              label="Code"
              type="number"
              ref="codeInput"
              v-model="code"
              :error="errorMessages!=''"
              :error-messages="errorMessages"
            />
            <va-button
              :rounded="false"
              style="margin: 0px !important;  margin-left: 20px !important; margin-bottom: 20px !important;"
              @click="submitCode"
              class="mt-4 mb-4">
                Submit
            </va-button>
          </div>
        </va-card>
      </va-inner-loading>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
	getRegionCodeForCountryCode,
  parsePhoneNumber,
} from 'awesome-phonenumber'

import { Auth } from 'aws-amplify'
const SMS_DELAY = 30000

export default {
  name: 'Auth',
  data() {
    return {
      countryCode: '',
      countryCodeRegion: '',
      isCountryCodeValid: false,
      phone: '',
      fullPhoneNumber: '',
      isValid: false,
      code: '',
      isCodeRequested: false,
      showCodeSubmit: false,
      cognitoUser: {},
      errorMessages: '',
      isLoading: false
    }
  },
  watch: {
    isCodeRequested (value) {
      // console.log('isCodeRequested: ', value)
      if (value === true) {
        setTimeout(() => {
          this.isCodeRequested = false
          this.saveLocalStorage ()
        }, SMS_DELAY)
      }
    },
    // When country code changes, enable phone number and store region code
    countryCode (value) {
      const region = getRegionCodeForCountryCode(value)
      if (region === 'ZZ') {
        this.isCountryCodeValid = false
        this.countryCodeRegion = ''
        this.phone = ''
        return
      }
      this.isCountryCodeValid = true
      this.countryCodeRegion = region

      // console.log(value, this.isCountryCodeValid, this.countryCodeRegion)
    },
    // When phone number changes, revalidate
    phone (value) {
      const pn = parsePhoneNumber(value, this.countryCodeRegion)
      // console.log(value, pn)
      this.isValid = pn.isValid()

      this.fullPhoneNumber = this.isValid ? pn.getNumber() : ''
    }
  },
  async mounted () {
    this.emitter.on('signOut', async () => (await this.signOut ()))

    if (!localStorage.UIstate) return
    const UIstate = JSON.parse(localStorage.UIstate)
    console.log('Mounted - Local storage: ', UIstate)

    this.phone = UIstate.phone
    this.countryCode = UIstate.countryCode
    await this.checkIfLoggedIn ()
    this.isLoading = false

    // Set focus on country code
    // this.$refs.countryCode.focus()
  },
  methods: {
    resetForm () {
      this.phone = ''
      this.countryCode = ''
      this.code = ''
      this.isCodeRequested = false
      this.errorMessages = ''
      this.cognitoUser = {}
      this.showCodeSubmit = false
      this.saveLocalStorage ()
    },
    saveLocalStorage () {
      const UIstate = {
        phone: this.phone,
        countryCode: this.countryCode
      }
      console.log('Saving Local storage: ', UIstate)

      localStorage.UIstate = JSON.stringify(UIstate)
      console.log('Saving: ', UIstate)
    },
    async getCode () {
      this.isCodeRequested = true
      this.showCodeSubmit = true
      this.code = ''
      this.errorMessages = ''
      this.$nextTick(() => this.$refs.codeInput.$el.focus())

      console.log('Saving: ', this.fullPhoneNumber)
      this.saveLocalStorage ()

      // Request the code
      await this.signUp ()
    },

    // AUTH METHODS
    getRandomString (length) {
      return Array(length).fill().map(()=>"abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".charAt(Math.random()*62)).join("")
    },
    async signIn () {
      try {
        console.log('Signing in: ', this.fullPhoneNumber)
        this.cognitoUser = await Auth.signIn(this.fullPhoneNumber)
      } catch (err) {
        if (err) {
          console.log(err)
          if (err.code === 'InvalidParameterException') {
            console.error ('signIn error: ', err.message)
            return
          }

          if (err.code === 'NotAuthorizedException') {
            console.error ('signIn error: ', err.message)
            return
          }
        }
      }
      console.log(this.cognitoUser)
    },
    async signUp () {
      const params = {
        username: this.fullPhoneNumber,
        phonenumber: this.fullPhoneNumber,
        password: this.getRandomString(30),
        attributes: {
          name: this.fullPhoneNumber
        }
      }
      try {
        console.log('signUp: ', params)
        this.cognitoUser = await Auth.signUp(params)
        console.log(this.cognitoUser)
      } catch (err) {
        if (err) console.error(err)
      }
      await this.signIn ()
    },
    async submitCode () {
      const code = this.code
      console.log(code)
      try {
        const result = await Auth.sendCustomChallengeAnswer(this.cognitoUser, code)
        console.log('Result:', code, result)
      } catch (err) {
        console.error ('submitCode error: ', err)
        this.resetForm ()
      }
      await this.checkIfLoggedIn ()
    },
    async checkIfLoggedIn () {
      const loggedIn = await this.isAuthenticated()
      console.log("checkIfLoggedIn: ", loggedIn)

      if (loggedIn) {
        const session = await Auth.currentSession()
        console.log(session)

        // Check if admin requirements are met
        if (this.$adminApp) {
          const groups = session.getIdToken().payload['cognito:groups']
          console.log('Groups: ', groups)

          if (!groups || !groups.includes('admin')) {
              console.log('Not an admin - signing out')
              this.errorMessages = "Insufficient privileges"
              return
          }
        }
        this.emitter.emit('authStateChanged', { loggedIn: true, authData: session })
      } else {
        this.errorMessages = "Invalid code"
      }
    },
    async signOut() {
      await Auth.signOut()
      this.resetForm ()
      this.emitter.emit('authStateChanged', { loggedIn: false })
    },
    async isAuthenticated() {
      try {
        await Auth.currentSession()
        return true
      } catch {
        return false
      }
    }
  }

}
</script>
<style>
.parent {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.phone {
  font-size: x-large
}
.code {
  font-size: x-large !important;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 100px;
  padding-right: 100px;
}
</style>